<template>
    <LiefengContentManage>
        <template v-slot:title>
            排号记录
            <span class="eye" @click.stop="changeEye">
                <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
            </span>
        </template>
        <template v-slot:toolsbarLeft>
            <!--      <RadioGroup   v-model="formItem.dateType" type="button" button-style="solid">-->
            <!--        <Radio label="WEEK">本周</Radio>-->
            <!--        &lt;!&ndash;        <Radio label="MONTH">本月</Radio>&ndash;&gt;-->
            <!--      </RadioGroup>-->
            <CheckboxGroup :value="dateTypeOld" @on-change="checkToggleFn">
                <Checkbox label="WEEK" border>本周</Checkbox>
            </CheckboxGroup>
        </template>
        <template v-slot:toolsbarRight>
            <template>
                <Form class="headerClass" :model="formItem" :label-width="90">
                    <FormItem label="姓名：" :label-width="60">
                        <Input v-model="formItem.userName" placeholder="通过姓名查询"></Input>
                    </FormItem>
                    <FormItem label="日期" :model="formItem" :label-width="60">
                        <Row>
                            <Col span="12">
                                <DatePicker v-model="formItem.operDate" @on-change="timeDataFn" type="date" placeholder="通过日期查询" style="width: 180px"></DatePicker>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="手机号" :label-width="70">
                        <Input v-model.trim="formItem.mobile" placeholder="通过手机号查询"></Input>
                    </FormItem>
                    <FormItem label="报名状态" :label-width="75">
                        <Select v-model="formItem.status" placeholder="通过报名状态查询">
                            <Option value="5">全部</Option>
                            <Option value="0">报名成功</Option>
                            <Option value="3">已取消</Option>
                        </Select>
                    </FormItem>
                    <template>
                        <Button type="primary" icon="ios-search-outline" style="margin-left: 10px" @click="search">搜索</Button>
                        <Button type="success" icon="ios-refresh" style="margin-left: 10px" @click="reset">重置</Button>
                        <Button type="error" icon="ios-arrow-back" style="margin-left: 10px" @click="clckBack">返回</Button>
                    </template>
                </Form>
            </template>
        </template>
        <template v-slot:contentArea>
            <div class="container">
                <LiefengModal title="取消报名" :value="cancelStatus" width="400px">
                    <template #contentarea>
                        <Form :label-width="100">
                            <FormItem>
                                <span slot="label" class="validate">取消原因</span>
                                <Select v-model="cancel" transfer>
                                    <Option value="0">不满足报名条件</Option>
                                    <Option value="1">用户自主取消</Option>
                                    <Option value="2">临时有事来不了</Option>
                                    <Option value="3">其他</Option>
                                </Select>
                            </FormItem>
                        </Form>
                    </template>
                    <template #toolsbar>
                        <Button type="info" style="margin-right: 10px" @click="cancelStatus = false">取消</Button>
                        <Button type="primary" @click="cancelSave">确定</Button>
                    </template>
                </LiefengModal>
                <LiefengTable
                    :talbeColumns="tableColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="tableSelect"
                ></LiefengTable>
            </div>
        </template>
    </LiefengContentManage>
</template>

<script>
//@route('/record')
import LiefengTable from "@/components/LiefengTable"
import LiefengContentManage from "@/views/childmanagement/LiefengContentManage"
import LiefengModal from "@/components/LiefengModal"

export default {
    components: {
        LiefengTable,
        LiefengContentManage,
        LiefengModal,
    },
    data() {
        return {
            //脱敏
            hasSensitive: true,
            //取消按钮
            infoId: "",
            cancel: "0",
            cancelStatus: false,
            dateTypeOld: [],
            //搜索
            formItem: {
                dateType: this.dateType ? this.dateType : "",
                status: "5",
            },

            tableSelectArr: [], //table选中的
            //table列表
            tableColumns: [
                {
                    title: "序号",
                    align: "center",
                    width: 80,
                    render: (h, params) => {
                        return h("span", {}, params.index + 1)
                    },
                },
                {
                    title: "所属社区",
                    key: "orgName",
                    minWidth: 130,
                    align: "center",
                },
                {
                    title: "姓名",
                    key: "userName",
                    align: "center",
                    minWidth: 120,
                },
                {
                    title: "手机号",
                    key: "mobile",
                    align: "center",
                    minWidth: 128,
                },
                {
                    title: "年龄",
                    key: "age",
                    minWidth: 80,
                    align: "center",
                },
                {
                    title: "性别",
                    key: "sex",
                    minWidth: 80,
                    align: "center",
                },
                {
                    title: "报名日期",
                    key: "operDate",
                    minWidth: 120,
                    align: "center",
                },
                {
                    title: "时间段",
                    key: "cycleTime",
                    minWidth: 150,
                    align: "center",
                },
                {
                    title: "报名时间",
                    key: "gmtCreate",
                    minWidth: 180,
                    align: "center",
                },
                {
                    title: "是否代报名",
                    key: "agent",
                    minWidth: 80,
                    align: "center",
                },
                {
                    title: "代报人",
                    key: "agentName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "代报人手机号",
                    key: "agentMobile",
                    width: 150,
                    align: "center",
                },
                {
                    title: "排号",
                    key: "cardNo",
                    fixed: "right",
                    width: 100,
                    align: "center",
                    render: (h, params) => {
                        let paihao = null
                        if (params.row.card !== null) {
                            paihao = params.row.card.cardNo
                        } else {
                            paihao = ""
                        }
                        return h("div", [h("span", {}, paihao)])
                    },
                },
                {
                    title: "报名状态",
                    key: "status",
                    fixed: "right",
                    width: 100,
                    align: "center",
                    render: (h, params) => {
                        let status = params.row.status
                        return h("div", [h("span", {}, status === "已取消" || status === "报名成功" ? status : "报名成功")])
                    },
                },
                {
                    title: "操作",
                    key: "action",
                    fixed: "right",
                    align: "center",
                    minWidth: 180,
                    render: (h, params) => {
                        // let toggle=false
                        // if(params.row.status==='已取消'){
                        //   toggle=false
                        // }else if(params.row.status==='报名成功'){
                        //   toggle=false
                        // }else {
                        //   toggle=true
                        // }
                        return h(
                            "Button",
                            {
                                props: {
                                    type: "primary",
                                    size: "small",
                                    disabled: params.row.status == "已取消" || this.$route.query.disabled ? true : false,
                                },
                                // style:{
                                //   display:toggle===true?'none':'inline-block'
                                // },
                                on: {
                                    click: () => {
                                        this.infoId = params.row.id
                                        this.cancel = "0"
                                        this.cancelStatus = true
                                    },
                                },
                            },
                            params.row.status == "已取消" ? "已取消" : "取消"
                        )
                    },
                },
            ],
            tableData: [], //table的数据
            loading: false, //table 加载
            page: 1, //分页
            total: 0, //总数量
            pageSize: 20, //每一页显示的数量
        }
    },
    created() {
        this.hadlePageSize({
            page: this.page,
            pageSize: this.pageSize,
        })
    },
    beforeMount() {
        this.tableHeight = window.innerHeight - 200 + ""
    },
    methods: {
        clckBack() {
            var index = parent.layer.getFrameIndex(window.name)
            if (index) {
                //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                this.$router.push({
                    path: "/activityindex",
                    query: { menuId: this.$route.query.menuCode },
                })
            }
        },
        tableSelect(data) {
            //表格选中
            this.tableSelectArr = JSON.parse(JSON.stringify(data))
            this.tableSelectDataId = data
        },

        //选择器
        changeSelect(val) {
            // console.log(val,'val')
        },

        //日期
        timeDataFn(data) {
            this.formItem.operDate = data
        },

        checkToggleFn(val) {
            this.dateTypeOld = val
        },

        // 取消
        cancelSave() {
            this.$post("/gateway/syinfopublish/api/pc/information/v2/changeRecordState", {
                idList: this.infoId,
                state: this.cancel,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "取消成功",
                        })
                        this.hadlePageSize({
                            page: this.page,
                            pageSize: this.pageSize,
                        })
                        this.cancelStatus = false
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "取消失败，请联系管理员处理",
                    })
                })
        },

        hadlePageSize(obj) {
            this.formItem.dateType = this.dateTypeOld.join()
            this.formItem.status = this.formItem.status === "5" ? "" : this.formItem.status
            this.loading = true
            this.$get("/old/api/pc/information/appointment/selectAppointmentUserCardPage", {
                informationId: this.$route.query.id,
                page: obj.page,
                pageSize: obj.pageSize,
                ...this.formItem,
                operDate: this.formItem.operDate,
                hasSensitive: this.hasSensitive,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            })
                .then(res => {
                    this.loading = false
                    if (res.code == 200 && res.dataList) {
                        res.dataList.map(item => {
                            if (item.status !== null && item.status !== undefined) {
                                switch (item.status) {
                                    case "0":
                                        item.status = "报名成功"
                                        break
                                    case "3":
                                        item.status = "已取消"
                                        item._disabled = true
                                        break
                                }
                            }
                            if (item.operDate) {
                                item.operDate = this.$core.formatDate(new Date(item.operDate), "yyyy-MM-dd")
                            }
                            if (item.gmtCreate) {
                                item.gmtCreate = this.$core.formatDate(new Date(item.gmtCreate), "yyyy-MM-dd hh:mm:ss")
                            }
                            if (item.agent) {
                                item.agent = item.agent == "0" ? "否" : item.agent == "1" ? "是" : ""
                            }
                            if (item.sex) {
                                item.sex = item.sex == "1" ? "男" : item.sex == "2" ? "女" : ""
                            }
                        })

                        this.tableData = res.dataList
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败",
                    })
                })
        },
        changeEye() {
            this.hasSensitive = !this.hasSensitive
            this.hadlePageSize({
                page: 1,
                pageSize: this.pageSize,
            })
        },
        //搜索
        search() {
            this.hadlePageSize({
                page: 1,
                pageSize: this.pageSize,
            })
        },
        //重置
        reset() {
            this.formItem = {}
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
    },
}
</script>

<style scoped lang='less'>
.container {
    height: calc(100vh - 85px);
    width: calc(100vw - 20px);
}

/deep/ #toolsbarLeft {
    //width: 300%;
    flex: 1;
}

/deep/ #toolsbarRight {
    flex: 4;
    //width: 1000%;
}

.headerClass {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    /deep/ .ivu-form-item {
        margin-bottom: 0;

        .ivu-form-item-content {
            input {
                width: 180px;
            }
        }

        .ivu-select-selection {
            width: 180px;
        }
    }
}
.eye {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    margin-left: 10px;
}
</style>


